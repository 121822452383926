// !!!! - DO NOT MODIFY - !!!!
// THIS FILE IS DEPLOYED AUTOMATICALLY BY MATTv2.
//
//  Deployment info:
//    Updated at:     2023-11-24T10:35:43+00:00
//    Cause:          Manual deployment.
//    Deployment ID:  176192
//    Project:        546
//    Environment:    4936 - support/multiadd-cleanup
//    MD5:            6725245f057526291be09ddd2b4c22f2
//    Signature:      eyJpdiI6ImZLRGpyNGhlZ0VaY21kN1YwZFFxZ0E9PSIsInZhbHVlIjoiTm5FaWtTVnlKOFNhSTdUVUhpZnRxRGJCcHZEWjVTdGVDUVJjZ0tKblNjbGxHWmhidWc3ZDJPdFdKc0lkT0NXVDFHd01HeTNxcE9ndW9nSXY3djRjZTlrWlhWNUxOb1JqWWxqRGdkM3pIR1M5SDA0T0dNdEs0M0t1cEFGTTB4TmVud0tDTWs5RnhYMnpTZjlNSGxXNGJqUXlsZ0hXcTN5aElYaWEzTlJYcm13ODFLcFNKdmp4QjFwR1ZydHhqRGdtTWpmbHI0RFZscDJ0UkpQNTJFYm1HWlpodndsSWFPNXBUZitOMjhyNDIrSjJaTWZzN0hiczFWTTNcL0pPMWdJVStWWUwzVDJtb1dScVFudlk5OXJJYnZDNFd0ZUloZG1tOWlTUUJNVG5xXC9acTJFa3JJODFNeWEyWWRhUUNVamdOZWU3eEU1YVIyb2dDWmRTV0xzakJGWnp5QktBMmdXckhySGpcL1p2XC8rUFkwanhqSTZJWTVOMnhaY3psS1RZWGsrQnZhYnd1dUdzZmxJamN6N1E5MWlYWWc9PSIsIm1hYyI6IjFkNzcwM2VkZGM4YTE3MWUzOGQyNDM5ZGYyMmYzNGVjNjhiNDZjZmVlZmNhN2NhNjcxNDA5MTI2YzMzMTlkZmIifQ==
if (!customElements.get('product-form')) {
  customElements.define('product-form', class ProductForm extends HTMLElement {
    constructor() {
      super();

      this.form = this.querySelector('form');
      this.productStickyBar = document.getElementById("product-sticky-bar");
      this.form.querySelector('[name=id]').disabled = false;
      this.cartNotification = document.querySelector('cart-notification');
      this.submitButton = this.querySelector('[type="submit"]');
      this.subscriptionCheckbox = document.querySelector('#rc_purchase_type_autodeliver');
      this.cartCountBubble = document.querySelector("[js-cart-count]");
      this.mainCart = document.getElementById("cart-notification");
      this.cartCount = document.getElementById("cart-notification-button");
      this.cartProducts = document.getElementById('cart-notification-product');
      this.cartDrawer = document.getElementById("cart-notification");
      this.variantCards = document.querySelectorAll('.variant-card');
      this.sellingPlan = document.querySelector('select[name="selling_plan"]') ? document.querySelector('select[name="selling_plan"]') : null;
      if (this.productStickyBar) {
          // If the form contains multiple variants,
          // and addToCart function on global.js handles the submission
        this.form.addEventListener('submit', this.onSubmitHandler.bind(this));
        this.stickyAddToCartBtn = this.productStickyBar.querySelector("[js-sticky-add-to-cart-btn]");

        const self = this;
        this.stickyAddToCartBtn.addEventListener('click', function(evt) {
          self.onSubmitHandler(evt);
        });
      }
      
    }

    onSubmitHandler(evt) {
      evt.preventDefault();
       
      if (this.submitButton.getAttribute('aria-disabled') === 'true') return;

      this.handleErrorMessage();
      this.cartNotification.setActiveElement(document.activeElement);

      this.submitButton.setAttribute('aria-disabled', true);
      if(this.productStickyBar && evt.type === 'click') {
        this.stickyAddToCartBtn.classList.add('loading');
      }else {
        this.submitButton.classList.add('loading');
        this.querySelector('.loading-overlay__spinner').classList.remove('hidden');
      };

      const config = fetchConfig();

      const formData = new FormData(this.form);

      const formDataObj = {};

      formData.forEach((value, key) => (formDataObj[key] = value));
    
      let sectionsToRender = this.cartNotification.getSectionsToRender().map((section) => section.id);
      
      let itemData = {
        items: []
      };

      let itemJson = {
        id: parseInt(formDataObj.id),
        quantity: formDataObj.quantity ? formDataObj.quantity : 1
      };

      if (this.variantCards.length < 1) {
        itemData.items.push(itemJson)
        itemData.sections = sectionsToRender;
        itemData.sections_url = window.location.pathname;
      } else {
        this.variantCards.forEach(item => {
          if(item.classList.contains('active')) {
            let variant_id = parseInt(item.getAttribute('data-variant-id'));
  
            let qty = item.querySelector('input[name="quantity"]').value;
            if (this.subscriptionCheckbox && this.subscriptionCheckbox.checked) {
              itemJson = {
                id: variant_id,
                quantity: qty,
                selling_plan: this.sellingPlan.value
              }
              
            } else {
              itemJson = {
                id: variant_id,
                quantity: qty
              }
            }

            itemData.items.push(itemJson);
          }
        })
      }

      config.body = JSON.stringify(itemData);

      fetch(`${routes.cart_add_url}.js`, config)
        .then((response) => response.json())
        .then((response) => {
          if (response.status) {
            this.handleErrorMessage(response.description);

            const soldOutMessage = this.submitButton.querySelector('.sold-out-message');
            if (!soldOutMessage) return;
            this.submitButton.setAttribute('aria-disabled', true);
            this.submitButton.querySelector('span').classList.add('hidden');
            soldOutMessage.classList.remove('hidden');
            this.error = true;
            return;
          }

          this.error = false;
          const quickAddModal = this.closest('quick-add-modal');
          if (quickAddModal) {
            document.body.addEventListener('modalClosed', () => {
              setTimeout(() => { this.cartNotification.renderContents(response) });
            }, { once: true });
            quickAddModal.hide(true);
          } else {
            if (response.key) {
              this.cartNotification.renderContents(response);
            } else {
              this.renderNewCart(response)
            }
            
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          if(this.productStickyBar && evt.type === 'click') {
            this.stickyAddToCartBtn.classList.remove('loading');
          }else {
            this.submitButton.classList.remove('loading');
            this.querySelector('.loading-overlay__spinner').classList.add('hidden');
          };
          if (!this.error) this.submitButton.removeAttribute('aria-disabled');
        });
    }

    handleErrorMessage(errorMessage = false) {
      this.errorMessageWrapper = this.errorMessageWrapper || this.querySelector('.product-form__error-message-wrapper');
      if (!this.errorMessageWrapper) return;
      this.errorMessage = this.errorMessage || this.errorMessageWrapper.querySelector('.product-form__error-message');

      this.errorMessageWrapper.toggleAttribute('hidden', !errorMessage);

      if (errorMessage) {
        this.errorMessage.textContent = errorMessage;
      }
    }

    renderNewCart(responseData) {
      
      this.cartProducts.innerHTML = '';
      let cartItem = '';
      responseData.items.forEach( item => {
        
      cartItem += `<div class='cart-item'>
          <div class="cart-notification-product__image global-media-settings">
            <img src="${item.image}" alt="${item.product_title}"loading="lazy">
          </div>
          <div>
            <h3 class="cart-notification-product__name h4">${item.product_title}</h3>
            <dl>
              <div class="product-option">
                <dt>${item.quantity}x </dt>
                <dd>${item.variant_title}</dd>
              </div>
            </dl>
          </div>
        </div>`
      })

      this.cartProducts.innerHTML = cartItem;
      
      fetch(window.Shopify.routes.root + 'cart.js', {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          }
      }).then(data => {
          return data.json();
          
      }).then(responseData => {
          
          this.cartDrawer.classList.add('active');
          this.submitButton.classList.remove('loading');

          this.cartCountBubble.innerHTML = responseData.item_count;
          this.cartCount.innerHTML = `View my cart (${responseData.item_count})`;
      });
    }
  });
}
